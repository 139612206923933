import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { IoIosArrowUp } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LuPlus } from "react-icons/lu";

function App() {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [accordionItems, setAccordionItems] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 

  useEffect(() => {
    fetchAccordionItems();
  }, []);

  //fetch from db
  const fetchAccordionItems = async () => {
    try {
      const response = await axios.get("http://137.184.235.60:4000/api/accordion");
      setAccordionItems(response.data || []);
    } catch (error) {
      console.error("Error fetching accordion items:", error);
    }
  };

  //pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = accordionItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //open accordion
  const toggleAccordion = async (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };

  //edit
  const handleEditAccordion = (id) => {
    setEditingId(id);
    const accordionToEdit = accordionItems.find(
      (accordion) => accordion._id === id
    );
    setEditedQuestion(accordionToEdit.question);
    setEditedAnswer(accordionToEdit.answer);
    setShowEditModal(true);
  };

  //delete
  const handleDeleteAccordion = async (id) => {
    try {
      await axios.delete(`http://137.184.235.60:4000/api/accordion/${id}`);
      const updatedAccordionItems = accordionItems.filter(
        (accordion) => accordion._id !== id
      );
      setAccordionItems(updatedAccordionItems);
      toast.success("FAQ Deleted successfully.", { autoClose: 1500 });
    } catch (error) {
      console.error("Error deleting accordion item:", error);
      toast.error("Error Deleting FAQ.", { autoClose: 1500 });
    }
  };

  //update
  const handleSaveAccordion = async () => {
    try {
      await axios.put(`http://137.184.235.60:4000/api/accordion/${editingId}`, {
        question: editedQuestion,
        answer: editedAnswer,
      });
      const updatedAccordionItems = accordionItems.map((accordion) => {
        if (accordion._id === editingId) {
          return {
            ...accordion,
            question: editedQuestion,
            answer: editedAnswer,
          };
        }
        return accordion;
      });
      setAccordionItems(updatedAccordionItems);
      setShowEditModal(false);
      toast.success("Changes Saved successfully.", { autoClose: 1500 });
    } catch (error) {
      console.error("Error editing accordion item:", error);
      toast.error("Error Saving Changes.", { autoClose: 1500 });
    }
  };

  //add
  const addAccordionItem = async () => {
    // Check if both question and answer fields are filled
    if (!question.trim() || !answer.trim()) {
      toast.error("Question and Answer fields cannot be empty.", {
        autoClose: 1500,
      });
      return; // Exit the function if fields are empty
    }

    try {
      const response = await axios.post("http://137.184.235.60:4000/api/accordion", {
        question,
        answer,
      });
      const newItem = response.data;
      setAccordionItems([...accordionItems, newItem]);
      setQuestion("");
      setAnswer("");
      setShowAddModal(false);
      toast.success("FAQ added successfully.", { autoClose: 1500 });
    } catch (error) {
      console.error("Error adding accordion item:", error);
      toast.error("Failed to add FAQ.", { autoClose: 1500 });
    }
  };

  //build
  const handleBuildClick = async () => {

    if (accordionItems.length < 10) {
      toast.error("Add at least ten questions before starting the build process.", { autoClose: 3000 });
      return;
    }

    setIsLoading(true);
    try {
      toast.info("Build process started, It may take a while...", {
        autoClose: 3000,
      });
      const response = await axios.post(
        "http://137.184.235.60:4000/api/accordion/build"
      );
      toast.success("Build process completed", { autoClose: 1500 });
    } catch (error) {
      console.error("Error during the build process", error);
      toast.error(
        "Error during the build process, add atleast ten questions and then try again",
        { autoClose: 3000 }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col bg-[#EDEDED] min-h-[100vh]">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="z-50 "
        style={{ width: "450px", fontFamily: "Roboto, sans-serif" }}
      />
      <div className="h-20 w-[100%] bg-[#0f334e] shadow-xl absolute">
        <div className="text-center text-white  text-[40px] font-bold justify-center">FAQs Dashboard</div>
      </div>
      <div className="mt-[25%] md:mt-[8%]"></div>
      <div className="md:mx-[15%] mx-[5%] z-[40] ">
        <div className="flex justify-between"></div>
        <div className="flex mt-[25px] gap-2 md:justify-start justify-center">
          <button
            onClick={() => setShowAddModal(true)}
            class="relative inline-flex items-center justify-center px-3 md:px-12 py-3 overflow-hidden font-medium text-[#0f334e] transition duration-300 ease-out border-2 border-[#0f334e] shadow-md group"
          >
            <span class="absolute inset-0 text-[20px] flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#0f334e] group-hover:translate-x-0 ease">
              <LuPlus />{" "}
            </span>
            <span class="absolute text-[20px] flex items-center justify-center w-full h-full text-[#0f334e] transition-all duration-300 transform group-hover:translate-x-full ease">
              Add
            </span>
            <span class="relative invisible">Button Text</span>
          </button>
          <button
            className={`bg-[#0f334e] hover:bg-[#0e2333] transition-all text-white px-8 md:px-20 py-3 border-none text-[20px] flex flex-row gap-2 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleBuildClick}
          >
            {isLoading ? (
              <div className="flex items-center">
                <div className="animate-spin disabled rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                <span>Building...</span>
              </div>
            ) : (
              <span>Build</span>
            )}
          </button>
        </div>
        <div className="mt-[3%]">
          {currentItems.map((accordion) => (
            <div
              key={accordion._id}
              className="mb-1 mt-3 bg-transparent md:mt-2 border-b border-black"
            >
              <h6 className="mb-0 ">
                <button
                  className=" gap-4  relative flex items-center w-full py-4 font-semibold text-left transition-all ease-in  cursor-pointer  text-slate-700 group text-dark-500"
                  onClick={() => toggleAccordion(accordion._id)}
                >
                  <div
                    className={`text-[20px] md:text-[25px]  p-1 transition-transform text-[#0f334e] ${
                      openAccordion === accordion._id
                        ? "rotate-180"
                        : "rotate-90"
                    }`}
                  >
                    <IoIosArrowUp />
                  </div>
                  <span className="flex w-[80%] md:w-[95%] ">
                    <div className="text-[20px] md:text-[25px] text-[#0f334e] md:break-all font-bold py-1">
                      {accordion.question}
                    </div>
                  </span>
                </button>
              </h6>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  openAccordion === accordion._id ? "max-h-96" : "max-h-0"
                }`}
              >
                <div className=" p-4 pb-8 pt-1 text-sm leading-normal text-blue-gray-500/80 flex justify-between">
                  <div className="w-[85%] md:w-[95%]  text-[15px] md:text-[20px] font-semibold">
                    {accordion.answer}
                  </div>
                  <div>
                    <div className="flex flex-col justify-center text-[20px] gap-3 md:gap-2">
                      <button
                        onClick={() => handleEditAccordion(accordion._id)}
                        className="inline-flex items-center px-2 py-2 md:px-4 md:py-3 bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-medium transition-all flex-row gap-1"
                      >
                        <div className="mt-[3px] text-[20px]">
                          <MdModeEditOutline />
                        </div>
                      </button>
                      <button
                        onClick={() => handleDeleteAccordion(accordion._id)}
                        className="inline-flex items-center px-2 py-2 md:px-4 md:py-3 bg-red-600 hover:bg-red-700 text-white text-sm font-medium transition-all flex-row gap-1"
                      >
                        <div className="mt-[3px] text-[20px]">
                          <FaRegTrashAlt />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>


      </div>

      <div className="flex justify-center mt-[20px] ">
          <nav>
            <ul className="pagination flex flex-row gap-3">
              {Array.from({
                length: Math.ceil(accordionItems.length / itemsPerPage),
              }).map((_, index) => (
                <li key={index} className="page-item">
                  <button
                    onClick={() => paginate(index + 1)}
                    className={`page-link text-[#0f334e] ${
                      currentPage === index + 1 ? "border border-[#0f334e]" : ""
                    } rounded-md px-3 py-1`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="h-20"></div>
      {showEditModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 left-0 w-screen h-full flex justify-center items-center  bg-black bg-opacity-80 z-50 "
        >
          <motion.div className="bg-white p-10 flex flex-col gap-6 ">
            <div className="flex justify-between">
              <div className="text-[20px] md:text-[30px]"></div>

              <div
                onClick={() => setShowEditModal(false)}
                className="cursor-pointer text-[20px] mt-[-3%] mr-[-3%]"
              >
                <IoClose />
              </div>
            </div>
            <div className="relative w-full min-w-[200px] h-11">
              <input
                className="w-[300px] text-[15px] font-medium tracking-wider md:w-[700px] h-full px-3 py-3 font-sans text-sm  transition-all dura bg-transparent border  peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 border-t-transparent focus:border-t-transparent border-blue-gray-200 focus:border-gray-500"
                placeholder=" "
                value={editedQuestion}
                onChange={(e) => setEditedQuestion(e.target.value)}
              />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent  before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent  after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-900 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                New Question
              </label>
            </div>
            <div className="relative w-full min-w-[200px] h-11">
              <textarea
                className="resize-none w-[300px] text-[15px] tracking-wider font-medium md:w-[700px] md:h-[130px] h-[200px] px-3 py-3 font-sans text-sm transition-all bg-transparent border  peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 border-t-transparent focus:border-t-transparent border-blue-gray-200 focus:border-gray-500"
                placeholder=" "
                value={editedAnswer}
                onChange={(e) => setEditedAnswer(e.target.value)}
              />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent  after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-900 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                Answer
              </label>
            </div>
            <div className="flex md:flex-row flex-col md:mt-[80px] mt-[150px] gap-2">
              <button
                onClick={handleSaveAccordion}
                class=" relative inline-flex group py-3 px-12 items-center justify-center  m-1 cursor-pointer border-b-4 border-l-2  active:shadow-none shadow-lg bg-[#0f334e]  text-white"
              >
                <span class="hidden md:flex absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                <span class="relative text-white">Save Changes</span>
              </button>
              <button
                onClick={() => setShowEditModal(false)}
                class=" relative inline-flex group py-3 px-10 items-center justify-center  m-1 cursor-pointer border-b-4 border-l-2  active:shadow-none shadow-lg bg-red-600  text-white"
              >
                <span class="hidden md:flex absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                <span class="relative text-white">Discard Changes</span>
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}

      {showAddModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 left-0 w-screen h-full flex justify-center items-center  bg-black bg-opacity-80 z-50 "
        >
          <motion.div className="bg-white p-10 flex flex-col gap-6 ">
            <div className="flex justify-between">
              <div className="text-[20px] md:text-[30px]"></div>

              <div
                onClick={() => setShowAddModal(false)}
                className="cursor-pointer text-[20px] mt-[-3%] mr-[-3%]"
              >
                <IoClose />
              </div>
            </div>
            <div className="relative w-full min-w-[200px] h-11">
              <input
                className="w-[300px] text-[15px] font-medium tracking-wider md:w-[700px] h-full px-3 py-3 font-sans text-sm  transition-all dura bg-transparent border  peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 border-t-transparent focus:border-t-transparent border-blue-gray-200 focus:border-gray-500"
                placeholder=" "
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm font-semibold text-[13px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent  before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent  after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-900 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                New Question
              </label>
            </div>
            <div className="relative w-full min-w-[200px] h-11">
              <textarea
                className="resize-none w-[300px] text-[15px] tracking-wider font-medium md:w-[700px] md:h-[130px] h-[200px] px-3 py-3 font-sans text-sm transition-all bg-transparent border  peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 border-t-transparent focus:border-t-transparent border-blue-gray-200 focus:border-gray-500"
                placeholder=" "
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm font-semibold text-[13px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent  before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent  after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-900 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                Answer
              </label>
            </div>

            <div className="flex md:flex-row flex-col mt-[150px] md:mt-[80px] gap-1">
              <button
                onClick={addAccordionItem}
                class=" relative inline-flex group py-3 px-12 items-center justify-center  m-1 cursor-pointer border-b-4 border-l-2  active:shadow-none shadow-lg bg-[#0f334e]  text-white"
              >
                <span class="hidden md:flex absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                <span class="relative text-white">Add</span>
              </button>
              <button
                onClick={() => setShowAddModal(false)}
                class=" relative inline-flex group py-3 px-10 items-center justify-center  m-1 cursor-pointer border-b-4 border-l-2  active:shadow-none shadow-lg bg-red-600  text-white"
              >
                <span class="hidden md:flex absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                <span class="relative text-white">Discard</span>
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default App;
